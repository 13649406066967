import * as React from "react";
import { Row, Col, Container } from 'react-bootstrap'
// import { IZimiState } from '../../../types/auth'

import { Switch, Route, withRouter, RouteComponentProps } from "react-router-dom";
import QRUrlTokenDecoder from "./QRUrlTokenDecoder";


interface IQrTokenMatch {
  qrToken: string;
}

type PageProps = RouteComponentProps<IQrTokenMatch>;


// interface OwnProps {
//   qrToken: string;
// }

interface DispathProps {
  orgsLoadConnect: (orgId: string) => void;
}
interface ReduxStateProps {
  // orgs: IOrganization[];
}

interface IProps extends PageProps, ReduxStateProps, DispathProps {
}

interface IState {
}

class QRUrl extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      orgs: [],
    }
  }

  render() {

    return (

      <Container>

        <Switch>
          <Route path='/decoder/a/:qrToken'>
            <QRUrlTokenDecoder />
          </Route>

          <Route exact path='/decoder/a'>
            <Row> <Col> <h2>QR page Decoder</h2></Col></Row>
            <Row>
              {/* {this.props.orgs && this.props.orgs.map(org => {
                return (<Col key={org.id} sm={12} md={6} lg={6} xl={4}>  </Col>)
              })} */}
            </Row>

          </Route>
        </Switch>

      </Container>


    );
  };

  UNSAFE_componentWillMount() {
    // if (this.props.orgs && this.props.orgs.length === 0) {
    // this.props.orgsLoadConnect(this.props.orgId);
    // }

  }
}

// const mapStateToProps = (state: IZimiState, ownProps: OwnProps): ReduxStateProps => {
//   return { orgs: ownProps.orgId ? state.blueprintState.orgState.orgs.filter(org => org.id === ownProps.orgId) : state.blueprintState.orgState.orgs }
// }


// const mapDispatchToProps: DispathProps = {
//    orgsLoadConnect: orgsLoad
// };

export default ((withRouter(QRUrl)));
