import * as React from "react";
import { Row, Col } from 'react-bootstrap'
// import { IZimiState } from '../../../types/auth'

import { withRouter, RouteComponentProps } from "react-router-dom";
import { QRDecoder } from "../../libs/QRDecoder";
import ReactJson from 'react-json-view'
import { Terms } from "../../libs/QRDecodeMappings";

// const addDeviceUrl = 'https://support.zimi.life/hc/en-au/articles/900005310023-Add-a-Device';
const addDeviceUrl = 'https://support.zimi.life/en/article/how-to-add-a-device-19o2wf3/';
const devicePageDimmer = 'https://zimi.life/product/dimmable-light-switch';
const devicePageGpo = 'https://zimi.life/product/power-point';
const devicePageFan = 'https://zimi.life/product/fan-light-controller';
const devicePageMPS = 'https://zimi.life/product/multi-purpose-switches';
const devicePageZcc = 'https://zimi.life/product/cloud-connect';
const devicePageUGDC = 'https://zimi.life/product/garage-controller';
const devicePagePmBc = 'https://zimi.life/product/blind-controller';
const devicePageSenoa = 'https://zimi.life/senoa/';
const devicePageSnas = 'https://zimi.life/senoa/';
const devicePageSmoke = 'https://zimi.life/smoke-alarm/';

const errorPage = '/error'

interface IQrTokenMatch {
  qrToken: string;
}

type PageProps = RouteComponentProps<IQrTokenMatch>;


// interface OwnProps {
//   qrToken: string;
// }

interface DispathProps {
  // orgsLoadConnect: (orgId: string) => void;
}
interface ReduxStateProps {
  // orgs: IOrganization[];
}

interface IProps extends PageProps, ReduxStateProps, DispathProps {
}

interface IState {
}

class QRUrlToken extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      orgs: [],
    }
  }

  render() {

    const qrToken = this.props.match.params.qrToken;
    let reformedQRToken = qrToken.replace('-', '+');
    reformedQRToken = reformedQRToken.replace('_', '/');

    const hexToken = QRDecoder.decodeQRTokenBase64(reformedQRToken);
    let seperatedHex = {};
    const decodedQR = QRDecoder.decodeQRHex(hexToken);
    if (decodedQR) {
      seperatedHex = decodedQR;
    }

    if (decodedQR?.qrType === Terms.qrtype.device) {
      window.location.href = addDeviceUrl;
    }

    if (decodedQR?.qrType === Terms.qrtype.devicebox) {
      switch (decodedQR.deviceType) {
        case Terms.deviceType["GPO(V1)"]:
        case Terms.deviceType["GPO(V2)"]:
          window.location.href = devicePageGpo;
          break;
        case Terms.deviceType["1G_10AX"]:
        case Terms.deviceType.MPS_1GH:
        case Terms.deviceType.MPS_1GV:
        case Terms.deviceType.MPS_2GH:
        case Terms.deviceType.MPS_2GV:
        case Terms.deviceType.MPS_3GV:
        case Terms.deviceType.MPS_4GH:
        case Terms.deviceType.MPS_4GV:
          window.location.href = devicePageMPS;
          break;
        case Terms.deviceType.DIM:
          window.location.href = devicePageDimmer;
          break;
        case Terms.deviceType.FAN:
          window.location.href = devicePageFan;
          break
        case Terms.deviceType.UGDC:
          window.location.href = devicePageUGDC;
          break;
        case Terms.deviceType.ZCC:
          window.location.href = devicePageZcc;
          break;
        case Terms.deviceType.SEN_1G_DIM_1G_SW:
        case Terms.deviceType.SEN_2G_BC:
        case Terms.deviceType.SEN_2G_DIM_2G_SW:
        case Terms.deviceType.SEN_2G_DIM_2G_SW_2G_BL:
        case Terms.deviceType.SEN_2G_GPO:
        case Terms.deviceType.SEN_2G_SW:
        case Terms.deviceType.SEN_4G_BC:
        case Terms.deviceType.SEN_4G_SW:
        case Terms.deviceType.SEN_6G_SW:
          window.location.href = devicePageSenoa;
          break;
        case Terms.deviceType["2G_BC"]:
        case Terms.deviceType["4G_BC"]:
          window.location.href = devicePagePmBc;
          break;
        case Terms.deviceType.SNAS_2G_GPO:
        case Terms.deviceType.SNAS_1G_DIM_2G_SW:
        case Terms.deviceType.SNAS_2G_DIM_1G_SW:
        case Terms.deviceType.SNAS_3G_SW:
        case Terms.deviceType.SNAS_2G_BC_1G_SW:
          window.location.href = devicePageSnas;
          break;
        case Terms.deviceType.SMOKE_ALARM:
          window.location.href = devicePageSmoke;

          break;

        // default:
        //   window.location.href = errorPage;

      }
    }
    // if (decodedQR?.qrType === undefined) {
    //   window.location.href = errorPage;
    // }

    return (

      <div>

        {/* <Row> <Col>
          <div> Token - {qrToken}</div>
          <div> ReformedQRToken - {reformedQRToken}</div>
          <div> hex - {hexToken}</div>

          <br></br>
          <div>QR Data</div>
          <ReactJson src={seperatedHex} name={'QRData'} displayDataTypes={false} />

        </Col></Row> */}



      </div>


    );
  };

  UNSAFE_componentWillMount() {
    // if (this.props.orgs && this.props.orgs.length === 0) {
    // this.props.orgsLoadConnect(this.props.orgId);
    // }

  }
}

// const mapStateToProps = (state: IZimiState, ownProps: OwnProps): ReduxStateProps => {
//   return { orgs: ownProps.orgId ? state.blueprintState.orgState.orgs.filter(org => org.id === ownProps.orgId) : state.blueprintState.orgState.orgs }
// }


// const mapDispatchToProps: DispathProps = {
//    orgsLoadConnect: orgsLoad
// };

export default ((withRouter(QRUrlToken)));
