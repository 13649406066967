
import { Switch } from "react-router-dom";
import LoggedOutRoute from "./ui/LoggedOutRoute";
import QRUrl from "./qr/QRUrl";
import ErrorUrl from "./qr/ErrorUrl";
import QRUrlB from "./qr/QRUrlB";
import QRUrlDecoder from "./qr/QRUrlDecoder";



const Pages = () => {
    return (
        <Switch>
            <LoggedOutRoute path="/a" component={QRUrl} />
            <LoggedOutRoute path="/b" component={QRUrlB} />
            <LoggedOutRoute path="/error" component={ErrorUrl} />
            <LoggedOutRoute path="/decoder" component={QRUrlDecoder} />
        </Switch>
    );
};

export default Pages;
