import * as React from "react";
import { Route } from "react-router-dom";

import { Container, Row, Col, Image } from 'react-bootstrap';

import './LoggedOutRoute.scss'

interface IProps {
  exact?: boolean;
  authRoles?: string[] ;
  path: string;
  component: React.ComponentType<any>;
  location?: any;
}

const LoggedOutRoute = ({
  component: Component,
  ...otherProps
}: IProps) => {



  return (
    <>
      <Container fluid id='landing'>
        <Row>
          <Col xs={{ span: 10, order: 1, offset: 1 }} md={{ span: 6, order: 1, offset: 3 }} lg={{ span: 6, order: 1, offset: 3 }} className='text-center'>
            <Row style={{ height: '20px' }}>
              <Col>
                {/* <Image src='https://storage.googleapis.com/zimi-ui-res/images/gsmlogo.png' className='logo' /> */}
              </Col>
            </Row>
            <div className='tab-content main'>
              <Row>
                <Col md={12} >
                  <Image src='https://storage.googleapis.com/zimi-ui-res/images/Zimi_Colored.png' className='logo' />
                </Col>
                {/* <Col md={7} >
                  <Image src='https://storage.googleapis.com/zimi-ui-res/images/Powermesh_Colored.png' className='logo' />
                </Col> */}
              </Row>
              <Route
                render={otherProps => (
                  <>
                    <Component {...otherProps} />
                  </>
                )}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};



export default (LoggedOutRoute);
