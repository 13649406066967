import { QRDecodeMappings, QRType } from "./QRDecodeMappings";

export interface IQR {
    type: QRType,
}

export interface IQRDevice extends IQR {
    type: QRType.device,
    branding: string,
    deviceType: string,
    batchCode: string,
    serial1: string,
    serial2: string

}


export interface IQRDeviceBox extends IQR {
    type: QRType.devicebox,
    branding: string,
    deviceType: string,
    batchCode: string,
    serial1: string,
    serial2: string

}

export class QRDecoder {
    public static decodeQRTokenBase64(tokenB64: string) {
        return Buffer.from(tokenB64, 'base64').toString('hex')
    }

    public static decodeQRHex(hexToken: string) {
        const decodedQRStr = QRDecoder.decodeQRHexStr(hexToken);

        const qrType = QRDecodeMappings.getQRTypeFromCodestring(decodedQRStr.qrTypeStr);
        if (qrType) {
            switch (qrType) {

                case QRType.device:
                case QRType.devicebox:
                    {
                        const decodedQR = {
                            qrType: QRDecodeMappings.getQRStringFromQRCodestring(decodedQRStr.qrTypeStr),
                            branding: QRDecodeMappings.getBrandingStrFromCodeStr(decodedQRStr.brandStr),
                            deviceType: QRDecodeMappings.getDeviceTypeStrFromCodeStr(decodedQRStr.deviceTypeStr),
                            batchCode: decodedQRStr.batchCode,
                            serial1: decodedQRStr.serial1Str,
                            serial2: decodedQRStr.serial2Str
                        }
                        return decodedQR;
                    }
                default:
                    return;

            }
        }


    }

    private static decodeQRHexStr(hexToken: string) {

        const qrTypeStr = hexToken.substr(0, 2);
        const deviceTypeStr = hexToken.substr(2, 4);
        const brandStr = hexToken.substr(6, 2);
        const batchCode = hexToken.substr(8, 4);
        const serial1Str = hexToken.substr(12, 12);
        const serial2Str = hexToken.substr(24, 12);

        return {
            qrTypeStr,
            deviceTypeStr,
            brandStr,
            batchCode,
            serial1Str,
            serial2Str
        }
    }




}