import * as React from "react";
import { Row, Col } from 'react-bootstrap'
// import { IZimiState } from '../../../types/auth'

import { withRouter, RouteComponentProps } from "react-router-dom";
import { QRDecoder } from "../../libs/QRDecoder";
import ReactJson from 'react-json-view'


interface IQrTokenMatch {
  qrToken: string;
}

type PageProps = RouteComponentProps<IQrTokenMatch>;


// interface OwnProps {
//   qrToken: string;
// }

interface DispathProps {
  // orgsLoadConnect: (orgId: string) => void;
}
interface ReduxStateProps {
  // orgs: IOrganization[];
}

interface IProps extends PageProps, ReduxStateProps, DispathProps {
}

interface IState {
}

class QRUrlToken extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      orgs: [],
    }
  }

  render() {

    const qrToken = this.props.match.params.qrToken;
    let reformedQRToken = qrToken.replace('-', '+');
    reformedQRToken = reformedQRToken.replace('_', '/');

    const hexToken = QRDecoder.decodeQRTokenBase64(reformedQRToken);
    let seperatedHex = {};
    const decodedQR = QRDecoder.decodeQRHex(hexToken);
    if (decodedQR) {
      seperatedHex = decodedQR;
    }

    return (

      <div>

         <Row> <Col>
          <div> Token - {qrToken}</div>
          <div> ReformedQRToken - {reformedQRToken}</div>
          <div> hex - {hexToken}</div>

          <br></br>
          <div>QR Data</div>
          <ReactJson src={seperatedHex} name={'QRData'} displayDataTypes={false} />

        </Col></Row> 



      </div>


    );
  };

  UNSAFE_componentWillMount() {
    // if (this.props.orgs && this.props.orgs.length === 0) {
    // this.props.orgsLoadConnect(this.props.orgId);
    // }

  }
}

// const mapStateToProps = (state: IZimiState, ownProps: OwnProps): ReduxStateProps => {
//   return { orgs: ownProps.orgId ? state.blueprintState.orgState.orgs.filter(org => org.id === ownProps.orgId) : state.blueprintState.orgState.orgs }
// }


// const mapDispatchToProps: DispathProps = {
//    orgsLoadConnect: orgsLoad
// };

export default ((withRouter(QRUrlToken)));
