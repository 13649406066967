import * as React from "react";
import { Row, Col, Container } from 'react-bootstrap'

import { Switch, Route, withRouter, RouteComponentProps } from "react-router-dom";


interface IQrTokenMatch {
  qrToken: string;
}

type PageProps = RouteComponentProps<IQrTokenMatch>;


interface DispathProps {
  orgsLoadConnect: (orgId: string) => void;
}
interface ReduxStateProps {
}

interface IProps extends PageProps, ReduxStateProps, DispathProps {
}

interface IState {
}

class ErrorUrl extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      orgs: [],
    }
  }

  render() {

    return (

      <Container>

        <Switch>
          <Route exact path='/error'>
            <Row> <Col> <h2>QR code not found</h2></Col></Row>

          </Route>
        </Switch>

      </Container>


    );
  };

  UNSAFE_componentWillMount() {

  }
}


export default ((withRouter(ErrorUrl)));
